import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.map.js";
function createSuklWidgetConfig() {
  var codes = ["00213049849", "29995053000", "00203257854"];
  var medicalProdIds = [13, 15];
  var vetProdIds = [19];
  var certificates = [].concat(_toConsumableArray(codes.map(function (code) {
    return {
      displayInContext: {
        productAttributeSets: medicalProdIds
      },
      image: {
        url: "/images/footer/sukl-checkout.png",
        alt: "Ov\u011B\u0159en\xED internetov\xFDch str\xE1nek a l\xE9k\xE1rny",
        width: 165,
        height: 138
      },
      link: "https://prehledy.sukl.cz/prehledy.html#/lekarny/".concat(code, "?verify=true")
    };
  })), _toConsumableArray(codes.map(function (code) {
    return {
      displayInContext: {
        productAttributeSets: vetProdIds
      },
      image: {
        url: "/images/footer/vet-sukl-checkout.png",
        alt: "Ov\u011B\u0159en\xED internetov\xFDch str\xE1nek a l\xE9k\xE1rny",
        width: 165,
        height: 138
      },
      link: "http://www.uskvbl.cz/cs/inspekce/lekarny/seznamy/detail?Id=".concat(code)
    };
  })));
  return {
    suklWidget: {
      codes: codes,
      medicalProdIds: medicalProdIds,
      vetProdIds: vetProdIds,
      certificates: certificates
    }
  };
}
export default defineAppConfig(createSuklWidgetConfig());