
export default {
  footer: {
    links: [
      {
        url: '/vse-o-nakupu/vseobecne-obchodni-podminky',
        text: 'Všeobecné obchodní podmínky',
      },
      {
        url: '/vse-o-nakupu/rezervacni-podminky',
        text: 'Rezervační podmínky',
      },
      {
        url: '/vse-o-nakupu/ochrana-ou',
        text: 'Ochrana osobních údajů v e-shopu',
      },
    ],
  },
}
