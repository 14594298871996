export default defineAppConfig({
  ssrCache: {
    metadata: {
      be: {
        code: "magento",
        region: "CZE"
      },
      fe: {
        businessUnit: "CZE",
        code: "nsf-drmax",
        region: "CZE"
      }
    }
  }
});